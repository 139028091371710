import React, { lazy } from 'react';
import { Slug } from './permissionConstants';
import {
  ActiveDashBoardIcon,
  DashboardIcon,
  HeatsActiveIcon,
  HeatsIcon
} from '../../assets/icons/modifiedIcons';
import LoadLazyComponent from '../../components/LoadLazyComponent';
import HeatDetailsHeader from '../../componentsV2/Layout/Header/HeatDetailsHeader';
import styles from '../../componentsV2/MeltingSection/Dashboard/MeltingDashboard.module.scss';

const HeatDetailsSection = lazy(() =>
  LoadLazyComponent(() => import('../../pages/Heats/index'))
);

const MeltingDashboard = lazy(() =>
  LoadLazyComponent(() =>
    import('../../componentsV2/MeltingSection/Dashboard/MeltingDashboard')
  )
);

export const Melting_Section_route = {
  name: 'MELTING SECTION',
  can_view: [Slug.MELTING_SECTION],
  child_routes: [
    {
      name: 'Dashboard',
      icon: DashboardIcon,
      active_icon: ActiveDashBoardIcon,
      component: <MeltingDashboard />,
      path: '/melting/dashboard',
      permission: [],
      slug: Slug.MELTING_SECTION,
      matchRoutes: '^/melting/dashboard(?:/.*)?$',
      header: () => (
        <div className={styles.meltingDashboardHeader}>Melting Dashboard</div>
      )
    },
    {
      name: 'Heat Details',
      icon: HeatsIcon,
      active_icon: HeatsActiveIcon,
      component: <HeatDetailsSection />,
      path: 'heats/*',
      permission: [],
      slug: Slug.MELTING_SECTION,
      matchRoutes: '^/heats(?:/.*)?$',
      header: () => <HeatDetailsHeader />
    }
  ]
};
